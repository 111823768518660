@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.ordersButton {
    font-family: 'Fira Sans', sans-serif;
    position: absolute;
    right: 16px;
    top: 19px;
    z-index: 2;
    padding: 0px 20px;
    background-color: white;
    border: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 50px;
}