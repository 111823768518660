@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.orderIsReadyWrapper {
    font-family: 'Fira Sans', sans-serif;
    position: fixed;
    z-index: 901;
    width: 100vw;
    min-height: 100vh;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .orderIsReadyOverlay {
        position: fixed;
        z-index: 810;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
    }
    .orderIsReadyBody {
        z-index: 999;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 16px 16px 0px 0px;
        .orderCoast {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 26px;
            height: 78px;
            :first-child {
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.408px;
            }
            :last-child {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                text-align: right;
                letter-spacing: -0.408px;
            }
        }
        .orderDetails {
            height: 62px;
            padding: 0 26px;
            border-top: 1px solid #888E99;
            border-bottom: 1px solid #888E99;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.408px;
        }
        .orderDetailsText {
            padding: 10px 40px 0 24px;
            p {
                margin-top: 15px;
            }
        }
        .bottomWrapper {
            padding: 50px 24px 0 24px;
            margin-bottom: 16px;
            button {
                width: 100%;
                height: 60px;
                background: #5364FF;
                border-radius: 8px;
                border: none;
                color: white;
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
            }
        }
    }
}
