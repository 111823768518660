
.vehicle_gray_button{
    padding: 8px;
    margin: 5px;
    background: #ECEDEF;
    border-radius: 8px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: #888E99;
    white-space: nowrap;
}
.vehicle_gray_button--white{
    background:#fff;
}


.vehicle_gray_button--white.selected,
.vehicle_gray_button.selected {
    background: #5364FF;
    color: #FFFFFF;
}


