@font-face {
  font-family: 'SF Compact Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Medium'), url('../../../../../assets/font/sf-compact-display-medium-5864711817c30.woff') format('woff');
  }

.datepicker_window_container {
  font-family: 'SF Compact Display Medium';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.datepicker_window {
  position: relative;
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  box-sizing: border-box;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  width: 90vw;
  z-index: 25;
}

.datepicker {
  width: 100%;
}

.datepicker .DayPicker-wrapper {
  position: relative;
  padding: 0 !important;
  outline: none;
}

.datepicker .DayPicker-Caption {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #FF3B30;
}

.datepicker .DayPicker-Day {
  color: #000000;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.6rem;
  letter-spacing: 0.38px;
  padding: 0.5rem;
  outline: none;
}

.datepicker .DayPicker-Day--disabled {
  color: #c6cad2;
}

.datepicker .DayPicker-Weekday {
  color: rgba(60, 60, 67, 0.3);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  text-transform: uppercase;
}

.datepicker .DayPicker-Month {
  margin: 0 !important;
  width: 100%;
}

.datepicker_navbar {
  position: absolute;
  top: 0.26rem;
  right: 1.6rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 3.3rem;
}

.datepicker_day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 38px;
  width: 38px;
}

.datepicker_day_off {
  width: 38px;
  height: 38px;
  background: rgba(255, 59, 48, 0.2);
  border-radius: 50%;
  color: #FF3B30;
  font-size: 20px;
  line-height: 24px;
}

.datepicker_time {
  padding: 0 5px;
}

.datapicker_time_fromto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.datapicker_time_fromto div {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.datapicker_time_fromto input {
  border: none;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 6px;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #000000;
  padding: 4px 8px;
  max-width: 100px;
}

.datapicker_time_fromto input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: transparent !important;
}

.datepicker_save {
  text-align: right;
  padding-right: 0.6rem;
}

.saveButton {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #5364FF;
}

@media screen and (max-device-width: 400px) {
  .datepicker .DayPicker-Day {
    padding: 0;
  }
}

@media screen and (max-device-width: 280px) {
  .datepicker_day, .datepicker_day_off {
    width: 20px;
  }
  .datepicker_navbar {
    right: 0;
    width: 2.5rem;
  }
}
