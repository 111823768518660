.autoSelectWrapper {
    position: absolute;
    width: 100%;
    min-height: calc(100% - 91px);
    top: 0;
    left: 0;
    z-index: 900;
    margin-top: 91px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .autoSelectBodyWrapper {
        position: absolute;
        z-index: 920;
        width: 100%;
        min-height: 50%;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .addItemButton {
            position: relative;
            bottom: 0;
            width: 100%;
            background: #C6CAD2;
            padding: 15px 0;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.408px;
        }
        .activeAddItemButton {
            background: #3B3F49;
        }
        .linkWrapper{
            color: #ff743c;
            text-decoration: underline;
            padding: 20px 0 ;
            text-align: center;
            background: #ECEDEF;
        }
        .autoSelectHeader {
            height: 60px;
            background: #ECEDEF;
            border-radius: 16px 16px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.408px;
        }

        .appendCargo {
            text-align: center;
            background: #fff;
            font-size: 18px;
            padding: 10px;
            text-transform: uppercase;
            font-weight: 500;
            border-top: 1px solid #bfbfbf;
        }
        .autoSelectBody {
            height: 100%;
            background: #ECEDEF;
            .select {
                padding: 10px;
                background: #FFFFFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #888E99;
                padding-bottom: 20px;
                border-bottom: 1px solid #bfbfbf;
                img {
                    margin-left: 7px;
                }
                div {
                    padding: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.408px;
                }
                .selected {
                    background: #ECEDEF;
                    border-radius: 8px;
                    color: #333;
                }
            }
            .clue {
                background: #FFFFFF;
                padding: 6px 16px 16px 16px;
                color: #888E99;
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.408px;
                text-align: center;
                border-bottom: 1px solid #888E99;
            }
            .params {
                background: #FFFFFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px;
                color: #888E99;
            }
            .bottomField {
                height: 100%;
                background: #ECEDEF;
                padding: 12px;
                min-height: 100px;
                max-height: 200px;
                overflow-y: scroll;
                margin: 5px 0;
                .infoMessage{
                    text-align: center;
                    color: #555;
                    padding-top: 30px;
                }
            }


            .saveButton {
                position: relative;
                bottom: 0;
                width: 100%;
                padding: 16px 24px;
                .button {
                    width: 100%;
                    padding: 10px 0;
                    background: var(--blue);
                    border-radius: 8px;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: -0.408px;

                }
                .disableSaveButton {
                    background: #C6CAD2;
                }
                .title{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: unset;

                }
            }
            .changeButtons {


                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 17px 13px;

                div {
                    border: none;
                    height: 58px;
                    width: 31%;
                    border-radius: 8px;
                    color: #FFF;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    display: flex;
                    justify-content:center;
                    align-items: center;
                }
                .saveChangesButton {
                    background: #00A99F;
                    color: #FFFFFF;
                }
                .cancelButton {
                    background: #FFFFFF;
                    color: #FF3131;

                }
                .removeItemButton {
                    background: #FFFFFF;
                    background: lightcoral;
                    color: #FF3131;
                    color: #FFFFFF;
                }
            }
        }
    }
}
