@mixin fontSWCL($size: 16px, $weight: normal, $color: #000, $line-height: 16px) {
  font-style: normal;
  font-size: $size;
  color: $color;
  font-weight: $weight;
  line-height: $line-height;
}

.VehicleBlockAuto{
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .categories {
    width: 100%;
    min-height: 197px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .cargoDetails {
      width: 100%;
      padding: 12px 12px 6px 12px;
      .errorDescription {
        text-align: center;
        height: 100px;
        color: #888E99;
      }
      .infoPanel {
        width: 100%;
        padding: 16px;
        background: #ECEDEF;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cargoParam {
          text-align: center;
          :first-child {
            color: #888E99;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.408px;
          }
          :last-child {
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.408px;
            sup {
              color: #000000;
            }
          }
        }
      }
    }
    .slider {
      position: relative;
      overflow-x: scroll;
      width: 100%;
      min-height: 110px;
      padding: 12px 10px 0 10px;
      display: flex;
      align-items: center;
      .carCategoryContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .carTypeWrapper {
          margin-right: 22px;
          height: 94px;
          min-width: 96px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          background: #ECEDEF;
          .textContainer {
            padding-left: 12px;
            .text {
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.408px;
              color: black;
            }
            .weight {
              font-weight: bold;
              font-size: 17px;
              line-height: 20px;
              letter-spacing: -0.408px;
              color: black;
            }
          }
        }
      }
      .sizesBlock {
        height: 96px;
        position: relative;
        overflow: auto;
        border: 2px solid #C6CAD2;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px;
        width: 100%;
        .cargoSizeBlock {
          width: 100%;
          height: 100%;
          position: absolute;
          padding: 1px;
          box-sizing: border-box;

          .sizeBlock {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 2px;
            background: #888E99;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
          .sizeBlockValue {
            @include fontSWCL(10px, bold, #fff, 16px);
            &:nth-child(2), &:nth-child(4) {
              position: absolute;
              top: calc(50% - 9px);
              transform: rotate(-90deg);
            }
            &:nth-child(2) {
              right: 5px;
            }
            &:nth-child(4) {
              left: 5px;
            }
          }
        }
      }
    }
    .paramsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.408px;
      color: #888E99;
      padding: 16px 0 10px 0;
      div {
        text-align: center;
        padding: 0 2.5px;
      }
    }
    .marbles {
      img {
        padding: 5px;
      }
    }
  }
  .body {
    width: 100%;
    min-height: 70px;
    background: #FFFFFF;
    overflow-y: scroll;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 16px;
  }
  .changeCargo{
    text-align: right;
    color: cadetblue;
    float: right;
    margin-top: -5px;
    width: 100%;
    padding-right: 16px;
    height: 10px;
  }
  .bodyType {
    width: 100%;
    min-height: 66px;
    background: #FFFFFF;
    border-top: 1px solid #ECEDEF;
    overflow-y: scroll;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 16px;
  }
  .bodyConditions {
    width: 100%;
    background: #FFFFFF;
    border-top: 1px solid #ECEDEF;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px;

  }
  .bodySize {
    width: 100%;
    min-height: 66px;
    background: #FFFFFF;
    border-top: 1px solid #ECEDEF;
    padding: 0 6px 0 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    div {
      padding: 8px;
      background: #ECEDEF;
      border-radius: 8px;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.408px;
      color: #888E99;
    }
    .selectedMaxSize {
      background: #5364FF;
      color: #FFFFFF;
    }
  }

  .buttonWrapper {
    width: 100%;
    min-height: 100px;
    background: #FFFFFF;
    padding: 3px 24px 16px 24px;
    .button {
      width: 100%;
      height: 60px;
      background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
      border-radius: 8px;
      color: #FFFFFF;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.408px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      .coast {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          margin-left: 6px;
        }
      }
    }
    .disabled {
      background: #ECEDEF;
      color: #888E99;
    }
    .linkWrapper{
      color: #ff743c;
      text-decoration: underline;
      padding: 20px 0 ;
      text-align: center;

    }
  }
  .unactiveButton {
    padding: 8px;
    white-space: nowrap;
    color: #888E99;
    margin: 5px;
    img {
      margin-left: 6px;
    }
  }
  .activeButton {
    padding: 8px;
    margin: 5px;
    background: #ECEDEF;
    border-radius: 8px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: #888E99;
    white-space: nowrap;
    img {
      margin-left: 6px;
    }
  }
  .selected {
    background: #5364FF;
    color: #FFFFFF;
  }
  .selectValueModeal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .selectValueModealHeader {
      height: 84px;
      width: 100%;
      background: #ECEDEF;
      border-radius: 16px 16px 0px 0px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.408px;
    }
    .selectValueModealBody {
      width: 100%;
      background: #FFFFFF;
      padding: 10px;
      .value {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.408px;
      }
      .selectedValue {
        background: #ECEDEF;
      }
      .TSHeight {
        input {
          margin-top: 15px;
          padding-left: 15px;
          border: none;
          outline: none;
          font-size: 17px;
          line-height: 20px;
          letter-spacing: -0.408px;
          color: #000000;
        }
        .selectButton {
          margin-top: 15px;
          height: 60px;
          background: #5364FF;
          border-radius: 8px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .selectMaxValueModeal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .selectMaxValueModealHeader {
      height: 84px;
      width: 100%;
      background: #ECEDEF;
      border-radius: 16px 16px 0px 0px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.408px;
    }
    .selectMaxValueModealBody {
      width: 100%;
      background: #FFFFFF;
      padding: 10px;
      .value {
        padding: 0 10px;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.408px;
        color: #888E99;
        input {
          border: none;
          text-align: right;
          outline: none;
        }
      }
      .maxSizeSelectButton {
        height: 60px;
        background: #5364FF;
        border-radius: 8px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .needMoreData{
    background: #fff;
    text-align: center;
    padding: 15px;
    .akaButton{
      background: #eee;
      padding: 10px 5px;
      border-radius: 8px;
    }
  }
  .bodyHidden{
    display:none;
  }
  .bodySizeWhite{
    background:#fff !important;
  }
}

.VehicleBlockAutoTab{
  border-radius: unset;



  max-height: 0;
  overflow: hidden;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;

  transition: max-height  0.36s ease;

  background: #f0f0f0;
  border-top: #e0e0e0 1px solid;
  //.categories{
  //    background:unset;
  //}
  .body, .bodyType, .bodySize, .bodyConditions{
    background:unset;
    border-top: #e0e0e0 1px solid;

  }

  //.leftArrow {
  //    background: #f0f0f0 !important;
  //}
  //.rightArrow {
  //    background: #f0f0f0 !important;
  //}
  //.leftGrad {
  //    background:linear-gradient(270deg, #f0f0f000, #f0f0f0) !important;
  //}
  //.rightGrad {
  //    background:linear-gradient(90deg, #f0f0f000, #f0f0f0) !important;
  //}
  //.activeCarType{
  //    background:#fff !important;
  //}



}

.active {

  max-height: 1000px;

}
