@mixin fontSWCL($size: 16px, $weight: normal, $color: #000, $line-height: 16px) {
    font-style: normal;
    font-size: $size;
    color: $color;
    font-weight: $weight;
    line-height: $line-height;
}

.cargoMapWrapper {
    position: absolute;
    z-index: 950;
    top: 0;
    left: 0;
    .layout {
        position: fixed;
        z-index: 960;
        width: 100vw;
        height: 100vh;
        background: #000000;
        opacity: 0.5;
    }
    .body {
        position: fixed;
        z-index: 970;
        margin-top: 150px;
        width: 100%;
        height: calc(100% - 150px);
        .header {
            width: 100%;
            height: 84px;
            background: #ECEDEF;
            border-radius: 16px 16px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.408px;
        }
        .text {
            width: 100%;
            height: 50px;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.408px;
            color: #888E99;
        }
        .cargo {
            width: 100%;
            height: calc(100% - 50px - 84px);
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            box-sizing: border-box;
            .sizesBlockModal {
                height: calc(100% - 20px);
                position: relative;
                overflow: auto;
                border: 2px solid #C6CAD2;
                border-radius: 4px;
                box-sizing: border-box;
                transform: scale(1, -1);
                .cargoSizeBlock {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    box-sizing: border-box;
                    padding: 1px;
                    .sizeBlock {
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        border-radius: 2px;
                        background: #888E99;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .sizeBlockValue {
                        transform: scale(1, -1);
                        @include fontSWCL(10px, bold, #fff, 16px);
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: -0.408px;
                        &:first-child {
                            position: absolute;
                            top: calc(50% - 9px);
                            div {
                                transform: rotate(-90deg);
                            }
                        }
                        &:first-child {
                            right: 10px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
}