.paymantDetailsWrapper {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    z-index: 100910;
    bottom: 0;
    left: 0;
    .paymantDetailsLayout {
        width: 100%;
        min-height: 100%;
        position: absolute;
        z-index: 920;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.5);
    }
    .paymantDetailsBody {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 930;
        .header {
            width: 100%;
            height: 84px;
            background: #ECEDEF;
            border-radius: 16px 16px 0px 0px;
            padding: 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.408px;
            }
        }
        .groupeHeader {
            padding: 12px 16px;
            color: #888E99;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            background: #ECEDEF;
        }
        .advancedRow {
            padding: 16px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .row {
            padding: 16px 18px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .lastRow {
            background: #FFFFFF;
        }
    }
}
