.created_order_window{
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    border-radius: 16px 16px 0 0;
    position: fixed;
    bottom: 0;
    /*min-height: 390px;*/
    z-index: 999;
    width:100%;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
}

.order_is_executing{
    min-height: unset;
    padding-bottom: 10px;
}

.order_is_executing_link {
    color: #ff743c;
    text-decoration: underline;
    padding: 10px 0 30px 0;
    text-align: center;
}
.created_order_window .collapse_address_button{
    position: absolute;
    background-color: var(--white);
    border-radius: 50px;
    width: 50px;
    top: -66px;
    left: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.25);
}


.created_order_window:before{
    content: ' ';
    background: #aaa;
    width: 120px;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    top: -20px;
    height: 7px;
    border-radius: 10px;
    z-index: 11111;
    opacity: .6;

}

.created_order_window.min_size{
    min-height: unset;
    max-height: 60px;
    overflow: hidden;
    transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);
}

.created_order_window.min_size > div{
    min-height: 60px;
    overflow-y: hidden;
}
