.vehicleSelectWrapper {
    position: absolute;
    z-index: 900;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    min-height: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;


    .needMoreData{
        background: #fff;
        text-align: center;
        padding: 15px;
        .akaButton{
            background: #eee;
            padding: 10px 5px;
            border-radius: 8px;
        }
    }
}
