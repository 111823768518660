.phoneInput {
    width: calc(100% - 20px);
    color: #000;
    background: #FFF;
    font-size: 17px;
    font-weight: normal;
    border-radius: 8px;
    border: none;
    padding: 14px 16px;
    margin: 0 10px;
    outline-color: #ecedef;
}

.phoneInput::active,
.phoneInput:focus {
    border: none;
    outline: none;
  }

.phoneInput::placeholder {
    color: #888e99;
}

.phoneInput-notValid {
    border: 1px solid red;
}
