.AddressBlock{
  background:#fff;
  border-radius: 10px;
  width: 100%;
  padding-top: 5px;
  -webkit-transition: top 0.3s;
  -moz-transition: top 0.3s;
  transition: all 2s linear;
  .addressInputWrapper {

    background-color: #ECEDEF;
    width: calc(100% - 10px);
    margin: 0 5px;

    height: 60px;
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    input {
      width: 100%;
      background-color: #ECEDEF;
      border: none;
      margin-left: 15px;
      outline: none;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.408px;
      cursor: pointer;
    }
  }
  .addressInputWrapperOne {
    margin-bottom: 10%;

  }
  .addressToInputWrapper {
    background-color: #FFFFFF;
    input {
      background-color: #FFFFFF;
    }
  }

  .dropDownAddressesWrapper {
    width: 100%;
    text-align: left;
    .nestedAddress {
      padding-left: 20px;
    }
  }
  .newOrderAddressItemWrapper {
    width: 100%;
    height: 66px;
    display: flex;
    padding: 6px;
    box-sizing: border-box;
    .newOrderAddressItem {
      padding: 14px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      background: #ECEDEF;
      border-radius: 8px;
      :first-child {
        padding-right: 6px;
      }
      :nth-child(2) {
        width: 100%;
      }
    }
    .timeFromTo{
      font-size: 0.8rem;
      float: right;
      font-weight: 500;
      color: #666;
    }
  }

  .toWhereShowButtonWrapper {
    background-color: #FFFFFF;
    width: 100%;
    min-height: 22px;
    text-align: center;
    padding: 6px;
    .toWhereShowButton {
      color: #5364FF;
      background: #FFFFFF;
      border: none;
      height: 60px;
      width: 70px;
      font-weight: 500;
      font-size: 17px;
    }
    .acceptAddressButton {
      width: 100%;
      height: 60px;
      background: #3B3F49;
      border-radius: 8px;
      color: #FFFFFF;
      border: none;
    }
  }
}

.AddressBlockTab{
  border-radius: unset;

  padding: 0px 10px 0px 10px;

  max-height: 0;
  overflow: hidden;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;

  transition: max-height 0.16s ease;

  background: #f0f0f0;
  border-top: #e0e0e0 1px solid;

  .newOrderAddressItem {
    background: #fff !important;
  }

}

.active {
  padding: 5px 10px 10px 10px;

  transition: height 4s ease-out;
  max-height: 500px;
}
