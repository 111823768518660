@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.findLocationButton {
    position: absolute;
    bottom: 100%;
    height: 50px;
    background: #5364FF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: white;
    padding: 0px 20px;
    border: none;
}

.newUserOrderWrapper {
    font-family: 'Fira Sans', sans-serif;
    .newUserOrder {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        .newUserOrderFormWrapper {
            background-color: white;
            border-radius: 16px 16px 0px 0px;
            padding: 1px 6px;
            width: 100%;
            display: flex;
            justify-content: center;

            .openToInputSelector {
                color: #5364FF;
                font-weight: 500;
                font-size: 17px;
                line-height: 40px;
                text-align: center;
                letter-spacing: -0.408px;
                width: 70px;
                margin: 0 auto;
            }
            .addressInputWrapper {
                background-color: #ECEDEF;
                width: 100%;
                height: 60px;
                border-radius: 8px;
                padding: 0 16px;
                display: flex;
                align-items: center;
                margin-top: 5px;
                input {
                    width: 100%;
                    background-color: #ECEDEF;
                    border: none;
                    margin-left: 15px;
                    outline: none;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                }
            }
            .addressToInputWrapper {
                background-color: #FFFFFF;
                input {
                    background-color: #FFFFFF;
                }
            }
        }


    }
    .hideElement {
        display: none;
    }
}

