@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.inputAddressModalWrapper {
    
    font-family: 'Fira Sans', sans-serif;
    position: fixed;
    z-index: 800;
    width: 100vw;
    min-height: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left:0;
    .inputAddressOverlay {
        position: fixed;
        z-index: 810;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
    }
    .inputAddressModal {
        z-index: 999;
        min-height: 85vh;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 16px 16px 0px 0px;
        .inputAddressModalHeader {
            border-bottom: 1px solid #C6CAD2;
            padding: 15px 22px;
            min-height: 72px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        .inputDirection {
            font-size: 14px;
            line-height: 17px;
            color: #888E99;
        }
        .inputRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            width: 100%;
            form {
                width: 100%;
                input {
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                    border: none;
                    width: 100%;
                    outline: none;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                    color: #000000;
                }
            }
            img {
                margin-left: 20px;
            }
            .advancedIMG {
                margin-left: 0;
                margin-right: 13px;
            }
            .timeToPoint {
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.408px;
                color: #888E99;
                white-space: nowrap;
            }
        }
    }
    .inputAddressModalBody {
        min-height: 60%;
        .advancedSettingsWrapper {
            background: #ECEDEF;
            width: 100%;
            min-height: 65%;
            .header {
                padding: 10px 16px;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.408px;
                color: #888E99;
            }
            .multipleInputWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .saveDeleteButtonContainer {
                padding: 10px 0;
            }
            .inputWrapper {
                padding: 10px 16px;
                width: 100%;
                .unchanged {
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                    color: #5364FF;
                }
                .chenged {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.408px;
                    color: #888E99;
                }
                .text {
                    font-family: "Fira Sans", sans-serif;
                    word-wrap: break-word;
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                }
                input {
                    width: 100%;
                    border: none;
                    background: none;
                    outline: none;
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                    color: #000000;
                }
            }
            .startedButtonContainer {
                width: 100%;
                padding: 0 24px;
                button {
                    width: 100%;
                    height: 60px;
                    border: none;
                    background: #5364FF;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: -0.408px;
                    color: #FFFFFF;
                    margin-bottom: 16px;
                }
            }
            .controllButtonContainer {
                width: 100%;
                padding: 0 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                    width: 100%;
                    height: 60px;
                    border: none;
                    background: #5364FF;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: -0.408px;
                    color: #FFFFFF;
                    margin-bottom: 16px;
                }
                :last-child {
                    margin-left: 10px;
                    background: #FFFFFF;
                    color: #FF3131;
                }
            }
        }
        .inputAddressVariant {
            padding: 15px;
        }
    }
    }
}

.transeformToAdvanced {
    //top: 35%;
    height: auto;
    bottom: 0;
    top: unset;
    min-height: unset;
    .transeformToAdvancedModal {
        min-height: 65%;
    }
}

.hideElement {
    display: none;
}
