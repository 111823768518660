.select-point-on-map{
    background: #FFF;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:fixed;
    bottom: 0;
    z-index:1000;
    visibility: visible;
    padding-bottom: 15px;
    left:0;
}
.select-point-on-map-address img{
    margin-right: 10px;
}
.select-point-on-map-address{
    display: flex;
    padding: 15px;
    padding-top: 25px;
}
.select-point-on-map button{
    width: calc(100% - 30px);
    height: 60px;
    border: none;
    background: #3B3F49;
    border-radius: 8px;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #FFFFFF;
    margin: 15px;
}
.select-point-on-map button:disabled{
    background:#999;
    color:#eee;
}
