.header {
    min-width: 100%;
    min-height: 72px;
    background: #ECEDEF;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    img {
        margin-right: 15px;
    }
    .addressesContainer {
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        overflow-x: hidden;
        box-sizing: border-box;
        .headerAddressItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: auto;
            //margin-right: 30px;
            img {
                padding-left: 5px;
                margin-right: 0;
            }
            .address {
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.408px;
                .street {
                    max-width: 100px;
                    text-overflow: ellipsis; 
                    overflow: hidden; 
                    white-space: nowrap;
                }
                .house {
                    margin-left: 5px;
                }
            }
            .addressCompletion {
                color: #888E99;
            }
            .time {
                white-space: nowrap;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.408px;
                color: #888E99;
            }
        }
        .firstAddress {

        }
    }
}
.notRounded{
    border-radius: unset;
    height: 56px;
    min-height:unset;
}
