@font-face {
    font-family: 'SF Compact Display Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SF Compact Display Medium'), url('../../../../../../assets/font/sf-compact-display-medium-5864711817c30.woff') format('woff');
}

.setTimeWrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 990;
    padding: 0 16px;
    .setTimeOverlay {
        position: fixed;
        width: 100vh;
        height: 100vh;
        left: 0;
        top: 0;
        background: #000000;
        opacity: 0.5;
    }
    .setTime {
        font-family: 'SF Compact Display Medium';
        position: fixed;
        width: calc(100% - 32px);
        bottom: 36px;
        padding: 16px 15px 20px 17px;
        background: #FFFFFF;
        box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
        border-radius: 13px;
        div {
            font-family: 'SF Compact Display Medium';
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 13px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.38px;
            color: #000000;
            input {
                font-family: 'SF Compact Display Medium';
                border: none;
                background: rgba(118, 118, 128, 0.12);
                border-radius: 6px;
                font-size: 22px;
                line-height: 28px;
                letter-spacing: 0.35px;
                color: #000000;
                padding: 4px 8px
            }
            input[type="time"]::-webkit-calendar-picker-indicator {
                display: none;
            }
            button {
                font-family: 'SF Compact Display Medium';
                height: 28px;
                border: none;
                background: none;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.35px;
            }
        }

        .save {
            color: #5364FF;
        }
        .cancel {
            color: #888E99;
        }
    }
}
