.cargoItemWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .quantity {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
    }
    .params {
        width: 100%;
        padding: 12px;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .itemBlockHeader {
            color: #888E99;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.408px;
        }
        .itemBlockParam {
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.408px;
        }
    }
    .selectedItem {
        border: 1px solid #00A99F;
    }
}