.AppendCargoForm{
  display: flex;
  position: fixed;
  background: #fff;
  z-index: 1000;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 96%;
  left: 2%;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  .line{
    border-bottom: 2px solid #e0e0e0;
    margin-left: -10px;
    margin-right: -10px;
  }
  .header{
    font-weight: 500;
    font-size: 20px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    height: 73px;
    padding-bottom: 5px;
  }
  .cargoParams{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding:10px 0;
  }
  .saveButton{
    background: #aaa;
    padding: 20px 10px;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 60px;
    letter-spacing: -0.408px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    margin: 10px 0;


  }
  .active{
    background:var(--blue);
  }
}

.CargoParamInput.focused{
  border-color:var(--dark-grey)
}



.CargoParamInput{

  display: flex;
  justify-content: center;
  flex-direction:column;
  .header{
    font-size: 16px;
    height: unset;
    padding-left: 12px;
    color: #888;
  }
  .CargoParamInputValue{
    display: flex;

    justify-content: center;
    margin:10px;
    font-size: 16px;
    border:2px solid #eee;
    padding: 7px 10px;
    width:69px;
    border-radius:6px;
    align-items: baseline;
    margin-top: 0;
    input{
      border: none;
      width: 100%;
      font-size: 16px;
      line-height:25px;
      outline: none;
    }
  }

}


