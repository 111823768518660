.wrappingSelectWrapper {
    position: fixed;
    z-index: 940;
    width: 100%;
    height: 100%;
    .layout {
        position: absolute;
        z-index: 950;
        background: #000000;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }
    .body {
        width: 100%;
        max-height: 90%;
        position: absolute;
        z-index: 960;
        bottom: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 16px 16px 0px 0px;
        .header {
            padding: 30px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.408px;

        }
        .text {
            padding-bottom: 10px;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.408px;
            color: #888E99;
        }
        .list {
            padding: 10px;
            overflow-y: scroll;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .item {
                width:calc( ( 100% - 40px ) / 3 );
                background: #ECEDEF;
                border-radius: 8px;
                padding: 16px 0;
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }
}