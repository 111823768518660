
.app_loading_form{
    width: 80%;
    height: 200px;
    position: fixed;
    top: calc(50% - 150px);
    left: 10%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #aaa;
    z-index: 1360;

}

.app_loading_form__spinner{
    display: flex;
    justify-content: center;
    margin-top: -10px;
}
.app_loading_form__title{
    padding:20px;
    padding-top: 30px;
    text-align: center;
    font-size: 22px;
    color:#666;
    font-weight: 500;
}
