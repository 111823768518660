:root {
  --black: #000000;
  --dark-grey: #3b3f49;
  --grey: #888e99;
  --medium-grey: #c6cad2;
  --light-grey: #ecedef;
  --white: #ffffff;
  --blue: #5364ff;
  --yellow: #ffb700;
  --orange: #ff9900;
  --green: #00bb61;
  --red: #ff3131;
  --violet: #00a99f;
  --red-gradient: linear-gradient(to right, #ff5d53, #ff6848, #ff743c, #ff8030, #ff8c22);
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.H_l_bottom.H_l_right {
  top: 60vh;
}

.H_imprint {
  display: none;
}