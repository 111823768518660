.image_view_window{
    position:fixed;
    background: #3B3F49;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    z-index: 100;
    color:#fff;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

.image_view_window__photo img{
    width:100%;
    border-radius:5px;
}
.image_view_window__buttons{
    padding:10px 20px;
    padding-top: 40px;
}
.image_view_window__photo{
    margin: 10px;
    padding-top: 50px;
}
.image_view_window__title{
    padding-top: 100px;
    width:100%;
}

.image_view_window__delete{
    padding-top: 40px;
    font-size:16px;
    color: darkorange;
    text-decoration: underline;
}
