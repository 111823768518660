.order_cargo_block{
    width:100%;
    padding: 12px 0;
    background:#fff;
    border-bottom: 1px solid #C6CAD2;
    box-sizing: border-box;
    min-height: 140px;
    position:relative;
    display: flex;
    justify-content: center;
}

.order_cargo_block .schema_cargo__loading,
.order_cargo_block .schema_cargo__error{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    text-shadow: 1px 1px #fff
}

.order_cargo_block .schema_cargo__error{
    color:red;
}


.schema_cargo {
    width: calc(100% - 80px);
    height: 120px;
    border:2px solid #eee;
    border-radius: 5px;
    position: relative;
}
.cargoSizeBlock {
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    padding: 1px;
}
.cargoSizeBlock .sizeBlock {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    /*background: #888E99;*/
    background: #888E99;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.cargoSizeBlock .sizeBlockValue {
    transform: scale(1, -1);
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
}
.cargoSizeBlock .sizeBlockValue:first-child {
    position: absolute;
    top: calc(50% - 9px);
    right: 10px;
    height: 15px;
}
.cargoSizeBlock .sizeBlockValue:first-child div{
    transform: rotate(-90deg);
}


.cargo_info{
    background: #ECEDEF;
    border-radius: 8px;
    width: calc(100% - 20px);
    min-height:50px;
    margin: 12px 10px;
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:16px;
}

.cargo_info_item_value{
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
    color: #000000;
}
.cargo_info_item_text{
    color: #888E99;
    font-size:12px
}
.cargo_info_item{
    display:flex;
    flex-direction: column;
    text-align: center;
}
