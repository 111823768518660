.user_profile{
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    background-color: var(--white);
    z-index: 1000;
    top: 0px;
}

.user_profile .attention{
    text-align: center;
    color: #999;
    padding-top: 40px;
}


.user_profile .back_arrow{
    padding-top: 35px;
    padding-left: 17px;
    display: flex;
    background: var(--white);
    z-index: 999;
    width: 100vw;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
}

.user_profile .back_arrow_top{
    margin-top: -59px;
    opacity: 0.5;
}


.user_profile .user_and_company_info{
    width: 100vw;
    margin-top: 34px;
}

.user_profile .user_and_company_info .fio_name{
    font-size: 16px;
    color: #888E99;
    line-height: 22px;
    font-weight: normal;
}

.user_profile .user_and_company_info .name{
    font-weight: 500;
    font-size: 24px;
    padding: 0px 24px;
}
.user_profile .user_and_company_info .name span{
    font-size: 13px;
    color: #8e8e8e;
}

.profile_menu_item{
    font-size: 17px;
    padding: 22px 24px;
    border-top: 1px solid rgb(198, 202, 210);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    color: var(--black);
    cursor: pointer;
}

.profile_menu_item.no-top-border{
    border-top: unset;
}
.profile_menu_item.no-bottom-border{
    border-bottom: unset;
}

.profile_menu_item .top-title{
     font-size: 14px;
     color: var(--grey);
 }


.profile_menu_item .value{
    font-size: 17px;
    align-items: center;
    color: var(--black);
}

.profile_menu_item .value.loading{
    display: flex;
    color: #999;
    font-size: 14px;
    width: 170px;
    justify-content: space-between;
}

.profile_edit_one_record{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease-in-out 0s;
    z-index: 100000;
    overflow-y: scroll;
}


.profile_edit_one_record_window{
    width: 100%;
    min-height: 80%;
    display: flex;
    position: absolute;
    top: 20%;
    left: 0;
    background: rgb(255, 255, 255);
    border-radius: 10px 10px 0px 0px;
    flex-direction: column;
}



.profile_edit_one_record_window .title{
    position: relative;
    font-size: 24px;
    font-weight: 500;
    background-color: var(--light-grey);
    border-radius: 10px 10px 0px 0px;
}


.profile_edit_one_record_window .content input{
    width: calc(100% - 30px);
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: normal;
    border-radius: 8px;
    border: 1px solid rgb(255, 255, 255);
    padding: 14px 16px;
    margin: 0px;
    font-family: "Fira Sans", sans-serif;
    margin-left: 15px;
    outline-color: #5364ff;
}



.profile_edit_one_record_window .content .text_row{
    font-size: 16px;
    margin: 1px 10px;
    width: calc(100% - 20px);
    color: #8e8e8e;
    display: flex;
    justify-content: space-between;
}

.profile_edit_one_record_window .content .text_row span{
    color:#000
}
.profile_edit_one_record_window .content  .text_details{
    margin: 1px 10px;
    font-style: italic;
}

.profile_edit_one_record_window .content{
    display: flex;
    gap: 1em;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    margin: 12px 0px;
}

.profile_edit_one_record_window .content-subtitle{
    text-align: center;
    font-size: 15px;
    color: rgb(136, 142, 153);
    margin-top: 28px;
    height: 100%;
}

.profile_edit_one_record_window .button-area{
    padding: 50px 24px;
}

.profile_edit_one_record_window button{
    width: 100%;
    background-color: var(--blue);
    background-image: var(--blue);
    border: none;
    border-radius: 8px;
    height: 56px;
    color: var(--white);
    font-size: 17px;
}

.profile_edit_one_record_window button:disabled{
    background: #8b9898;
}


.profile_edit_one_record_window .title .inner-text{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 24px 30px;
    font-size: 20px;
    font-weight: 500;
}


.button_exit_profile{
    border: none;
    height: 58px;
    width: calc(100% - 40px);
    border-radius: 8px;
    color: #FFF;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    background: #5364ff;
    margin: 40px 20px;

}
.button_exit_profile.first{
    margin-bottom: unset;
}

.menu-items .linkWrapper{
    color: #ff743c;
    text-decoration: underline;
    padding: 40px 0;
    text-align: center;
}

.menu-items .separator{
    color: #555;
    background: #f0f0f0;
    padding: 20px 10px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    border-top: 1px solid #bbb;
}

.address_edit_mode{
    color:#75758b;
    padding:10px;
    font-size: 12px;
    font-style: italic;
}

.saving-button{
    width: 100%;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;
    height: 56px;
    color: #999;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-menu-item{
    font-size: 17px;
    padding: 22px 24px;
    text-align: center;
    background: #f0f0f0;
    border-top: 1px solid #bbb;
}

.small-padding-up{
    padding-top: 10px;
}
