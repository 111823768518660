.map {
  position: relative;
  width: 100vw;
  height: 100vh;

  .iconContainer {
    position: fixed;
    bottom: -12px;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .markerNumber {
      z-index: 5;
      background: #fff;
      padding: 5px;
      font-size: 12px;
      border-radius: 7px;
      min-width: 23px;
      text-align: center;
      width: 120px;
      white-space: pre-wrap;
      overflow: hidden;
      height: auto;
      letter-spacing: -0.44px;
      :first-child {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #888E99;
      }
      :last-child {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}


.debug_info{
  position: fixed;
  left: 10px;
  top: 66px;
  font-size: 13px;
  text-align: center;
  text-shadow: 1px 1px 3px #fff, -1px -1px 3px #fff;
  div:first-child{
    color: blue;
  }
  opacity: 0.9;
  background: #fff;
  border-radius: 3px;
  padding: 2px;
}
