.button_tariff{
    width: 100%;
    background: #FFFFFF;
    padding: 25px 24px 0px 24px;
}


.button_tariff .button {
    width: 100%;
    height: 60px;
    background: #5364FF;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.button_tariff.orange .button{
    background: linear-gradient(
            270deg
            , #FF8C22 0%, #FF5D53 99.71%);
}

.button_tariff .coast {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.button_tariff img {
    margin-left: 6px;
}

.button_tariff .disabled {
    background: #ECEDEF;
    color: #888E99;
}
.button_tariff .linkWrapper{
    color: #ff743c;
    text-decoration: underline;
    padding: 20px 0 ;
    text-align: center;
}
