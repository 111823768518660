@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');


%inputWrapper {
    background: #ECEDEF;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    input {
        background: none;
        border: none;
        width: 100%;
        outline: none;
    }
}

%paymentInputWrapper {
    width: 100%;
    height: 50px;
    border: none;
    background: #ECEDEF;
    border-radius: 8px;
}

%cost {
    white-space: nowrap;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.408px;
}

.orderCompletionWrapper {
    position: absolute;
    z-index: 900;
    width: 100%;
    min-height: 950px;
    top: 211px;
    left: 0;
    .header {
        min-width: 100%;
        min-height: 72px;
        background: #ECEDEF;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 20px;
        img {
            margin-right: 15px;
        }
        .addressesContainer {
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            overflow-x: hide;
            box-sizing: border-box;
            .headerAddressItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 109px;
                margin-right: 15px;
                img {
                    padding-left: 5px;
                    margin-right: 0;
                }
                .address {
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: -0.408px;
                    .street {
                        max-width: 100px;
                        text-overflow: ellipsis; 
                        overflow: hidden; 
                        white-space: nowrap;
                    }
                    .house {
                        margin-left: 5px;
                    }
                }
                .time {
                    white-space: nowrap;
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: -0.408px;
                    color: #888E99;
                }
            }
            .firstAddress {
                width: 130px;
            }
        }
    }
    .categoryDetails {
        min-height: 56px;
        background: #ECEDEF;
        border-top: 1px solid #888E99;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.408px;
        color: #888E99;
        padding-right: 12px;
    }
    .additionalRequirements {
        min-height: 64px;
        background: #FFFFFF;
        border-top: 1px solid #888E99;
        padding: 0 32px 0 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: auto;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.408px;
        border-bottom: 1px solid #888E99;
        .aditionalRequirement {
            background: #ECEDEF;
            border-radius: 8px;
            padding: 10px;
            white-space: nowrap;
            margin-left: 10px;
            color: #888E99;
        }
        .selectedRequirement {
            background: #5364FF;
            color: #FFFFFF;
        }
    }
    .tariffs {
        min-height: 200px;
        background: #FFFFFF;
        padding: 0 10px;
        .tariffTypes {
            min-height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 22.5px 0 12.5px;
            div {
                width: 100%;
                height: 38px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.408px;
                color: #888E99;
            }
            .active {
                background: #5364FF;
                color: #FFFFFF;
            }
            .activePM {
                background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
                color: #FFFFFF;
            }
        }
        .tariffRow {
            border-radius: 8px;
            height: 69px;
            width: 100%;
            padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.408px;
            div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                span {
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: -0.408px;
                }
                a {
                    white-space: nowrap;
                    text-decoration: none;
                    color: #FFB700;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            .tariffName {
                width: 100%;
                padding: 3px 0 0 14px;
            }
            big {
                white-space: nowrap;
            }
            .cost {
                padding: 0 14px 0 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-start;
                :first-child {
                    @extend %cost;
                    color: #FFFFFF;
                    opacity: 0.5;
                    text-decoration-line: line-through;
                    font-size: 17px;
                }
                :last-child {
                    @extend %cost;
                    margin-left: 14px;
                }
            }
            .costPM {
                color: #FF9900;
            }
        }
        .tarifPMRow {
            .cost {
                :first-child {
                    color: #C6CAD2;
                }
            }
        }
        .PMRow {
            background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
            color: #FFFFFF;
            div {
                a {
                    color: #FFFFFF;
                }
            }
        }
        .selectedRow {
            background: #5364FF;
            color: #FFFFFF;
            .cost {
                :first-child {
                    color: #C6CAD2;
                    text-decoration-line: line-through;
                }
            }
        }
    }
    .paymentDetails {
        min-height: 62px;
        background: #FFFFFF;
        padding: 0 20px 0 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .userRegistration {
        padding: 16px;
        background: #FFFFFF;
        border-top: 1px solid #888E99;
        min-height: 272px;
        .FIOWrapper {
            @extend %inputWrapper;
        }
        .phoneWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .phone {
                @extend %inputWrapper;
            }
            .checkCode {
                @extend %inputWrapper;
                width: 50%;
                margin-left: 10px;
            }
            .hideCode {
                display: none;
            }
        }
        .emailWrapper {
            @extend %inputWrapper;
        }
        .acceptWrapper {
            display: flex;
            justify-content: left;
            align-items: flex-start;
            padding-top: 10px;
            div {
                padding-left: 16px;
                a {
                    color: #000000;
                }
            }
        }
    }
    .paymentMethod {
        min-height: 140px;
        background: #FFFFFF;
        border-top: 1px solid #888E99;
        .paymentButtons {
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.408px;
            div {
                padding: 10px;
            }
            .selected {
                background: #5364FF;
                border-radius: 8px;
                color:#FFFFFF;
            }
            .unselected {
                color: #888E99;
            }
        }
        .paymentData {
            padding: 10px;
            .card {
                @extend %paymentInputWrapper;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px 0 16px;
                color: #5364FF;
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                letter-spacing: -0.408px;
            }
            [hidden] {
                display: none !important;
            }
            .INN {
                padding: 0 6px;
                input {
                    @extend %paymentInputWrapper;
                    padding: 0 16px;
                    outline: none;
                }
            .companyName{
                margin-top: -50px;
                margin-left: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                overflow: hidden;
            }
            }
            .cash {
                min-height: 50px;
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.408px;
                color: #5364FF;
            }
        }
    }
    .createOrder {
        min-height: 122px;
        padding: 12px 24px;
        .errorMessage {
            color: #FF3131;
            width: 100%;
            text-align: center;
            margin-bottom: 16px;
        }
        button {
            width: 100%;
            height: 60px;
            border: none;
            background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
            border-radius: 8px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.408px;
            font-family: "Fira Sans", sans-serif;
            .creatingOrder{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: unset;
                margin-top: 0px;
                color: #888;
            }
        }
        .disabledOrderButton {
            background: #ECEDEF;
            color: #C6CAD2;
        }
        .linkWrapper{
            color: #ff743c;
            text-decoration: underline;
            padding: 20px 0 ;
            text-align: center;

        }
    }
}


.orderCompletionHidden {
    visibility:hidden;
}
