.drop-down {
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    margin: 5px;
    z-index: 1;
}
.drop-down__toggler {
    width: 12px;
    height: 13px;
    transition: 0.3s ease-in-out;
}

.drop-down--show{
    z-index: 10;
}

.drop-down--show .drop-down__toggler {
    transform: rotate(180deg);

}

.drop-down--center {
    justify-content: center;
}
.drop-down--left {
    justify-content: flex-start;
}
.drop-down--right {
    justify-content: flex-end;
}

.drop-down--normal {
    padding: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
}
.drop-down--normal .drop-down__toggler {
    margin-left: 6px;
}
.drop-down--normal .drop-down__item {
    padding: 8px;
}
.drop-down--lg {
    font-weight: 500;
    padding: 9.5px 12px;
}
.drop-down--lg .drop-down__toggler {
    margin-left: 8px;
}
.drop-down--lg .drop-down__item {
    padding: 9px 12px;
}
.drop-down--xl {
    font-weight: 500;
    padding: 16.5px 20px;
}
.drop-down--xl .drop-down__toggler {
    margin-left: 8px;
}
.drop-down--xl .drop-down__item {
    padding: 9px 12px;
}

.drop-down--gray {
    background-color: #ecedef;
    color: #888e99;
}
.drop-down--white {
    background-color: #fff;
    color: #888e99;
}
.drop-down--blue {
    background: #ecedef;
    color: #5364ff;
}
.drop-down--transparent-green.drop-down-selected{
    color: #00A99F;
    border-bottom: 2px solid #00A99F;
 }
.drop-down-selected{

    color: #fff;
    background: #5364ff;
}

.drop-down--blue .drop-down__toggler svg path {
    stroke: #5364ff;
}
.drop-down--transparent, .drop-down--transparent-green {
    color: #888e99;
    background: transparent;
}

.drop-down--transparent-green{
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 16px 20px;
}

.drop-down__header {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    font-weight: 500;
}
.drop-down__container {
    position: absolute;
    top: calc(100% - 6px);
    left: 0;
    min-width: 100%;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding-top: 4px;
}
.drop-down__list {
    padding: 0;
    margin: 0;
}
.drop-down__item {
    list-style: none;
    color: #000;
    font-size: 15px;
}


.drop-down-select-modal-window {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background:  #00000050;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: gray;
}
.drop-down-select-modal-window .header {
    height: 84px;
    width: 100%;
    background: #ECEDEF;
    border-radius: 16px 16px 0px 0px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.408px;
    color: gray;
}
.drop-down-select-modal-window .body {
    width: 100%;
    background: #FFFFFF;
    padding: 10px;
}

.drop-down-select-modal-window .value {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.408px;
    color: gray;
}
.drop-down-select-modal-window .selected-value {
    background: #ECEDEF;
}
.drop-down-select-modal-window .TSHeight  input {
    margin-top: 15px;
    padding-left: 15px;
    border: none;
    outline: none;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
    color: #000000;
}
.drop-down-select-modal-window  .select-button {
    margin-top: 15px;
    height: 60px;
    background: #5364FF;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.drop-down-select-modal-window  .select-button.active{
    color: #fff;
}
