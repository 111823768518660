%active {
    margin-left: 10px;
    height: 34px;
    border: 2px solid #3B3F49;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFF;
    color: black;
}

.newOrderWrapper {
    position: fixed;
    bottom: 0;
    margin-top: 291px;
    width: 100%;
    left: 0;

    box-sizing: border-box;
    z-index: 333;
    .newOrder {
        background: #FFF;
        width: 100%;
        min-height: 100%;
        border-radius: 16px 16px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .toWhereShowButtonWrapper {
            background-color: #FFFFFF;
            width: 100%;
            min-height: 22px;
            text-align: center;
            padding: 6px;
            .toWhereShowButton {
                color: #5364FF;
                background: #FFFFFF;
                border: none;
                height: 60px;
                width: 70px;
                font-weight: 500;
                font-size: 17px;
            }
            .acceptAddressButton {
                width: 100%;
                height: 60px;
                background: #3B3F49;
                border-radius: 8px;
                color: #FFFFFF;
                border: none;
            }
        }

        .newOrderCoast {
            line-height: 100px;
        }

        .errorText {
            font-size: 14px;
            padding: 5px;
            color: red;
            font-weight: 600;
        }
        .controllButtons {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 10px;
            button {
                border: none;
                height: 58px;
                width: 100%;
                border-radius: 8px;
                color: #FFF;
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
            }
            :first-child {
                background: #5364FF;
            }
            :last-child {
                background: #00A99F;
                margin-left: 10px;
            }
        }
        .disabled {
            :first-child {
                background: #ECEDEF;
                color: #888E99;
            }
            :last-child {
                background: #ECEDEF;
                color: #888E99;
            }
        }
    }
}

.hideNewOrderWindow {
    visibility: hidden;
    transition: visibility 0s;
}

@media screen and (max-width: 400px) {
        .activeDate {
            letter-spacing: -0.5px;
            padding: 1px !important;
            word-spacing: -1px;
        }
}
