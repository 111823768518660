@mixin fontSWCL($size: 16px, $weight: normal, $color: #000, $line-height: 16px) {
    font-style: normal;
    font-size: $size;
    color: $color;
    font-weight: $weight;
    line-height: $line-height;
}


.vehicleSelectWrapper {
    position: absolute;
    z-index: 900;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .buttonWrapper {
        width: 100%;
        min-height: 100px;
        background: #FFFFFF;
        padding: 3px 24px 16px 24px;
        .button {
            width: 100%;
            height: 60px;
            background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
            border-radius: 8px;
            color: #FFFFFF;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.408px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            .coast {
                display: flex;
                justify-content: space-between;
                align-items: center;
                img {
                    margin-left: 6px;
                }
            }
        }
        .disabled {
            background: #ECEDEF;
            color: #888E99;
        }
        .linkWrapper{
            color: #ff743c;
            text-decoration: underline;
            padding: 20px 0 ;
            text-align: center;

        }
    }
    .unactiveButton {
        padding: 8px;
        white-space: nowrap;
        color: #888E99;
        margin: 5px;
        img {
            margin-left: 6px;
        }
    }
    .activeButton {
        padding: 8px;
        margin: 5px;
        background: #ECEDEF;
        border-radius: 8px;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.408px;
        color: #888E99;
        white-space: nowrap;
        img {
            margin-left: 6px;
        }
    }
    .selected {
        background: #5364FF;
        color: #FFFFFF;
    }
    .selectValueModeal {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .selectValueModealHeader {
            height: 84px;
            width: 100%;
            background: #ECEDEF;
            border-radius: 16px 16px 0px 0px;
            padding: 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.408px;
        }
        .selectValueModealBody {
            width: 100%;
            background: #FFFFFF;
            padding: 10px;
            .value {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                letter-spacing: -0.408px;
            }
            .selectedValue {
                background: #ECEDEF;
            }
            .TSHeight {
                input {
                    margin-top: 15px;
                    padding-left: 15px;
                    border: none;
                    outline: none;
                    font-size: 17px;
                    line-height: 20px;                  
                    letter-spacing: -0.408px;
                    color: #000000;
                }
                .selectButton {
                    margin-top: 15px;
                    height: 60px;
                    background: #5364FF;
                    border-radius: 8px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .selectMaxValueModeal {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .selectMaxValueModealHeader {
            height: 84px;
            width: 100%;
            background: #ECEDEF;
            border-radius: 16px 16px 0px 0px;
            padding: 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.408px;
        }
        .selectMaxValueModealBody {
            width: 100%;
            background: #FFFFFF;
            padding: 10px;
            .value {
                padding: 0 10px;
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
                letter-spacing: -0.408px;
                color: #888E99;
                input {
                    border: none;
                    text-align: right;
                    outline: none;
                }
            }
            .maxSizeSelectButton {
                height: 60px;
                background: #5364FF;
                border-radius: 8px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .needMoreData{
        background: #fff;
        text-align: center;
        padding: 15px;
        .akaButton{
            background: #eee;
            padding: 10px 5px;
            border-radius: 8px;
        }
    }
}
