%active {
  border-radius: 8px;
  border: 2px  solid #333;
  color: #333;
  font-weight: 600;
}

.newOrderHeaderWrapper {
  padding: 10px 6px 10px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px 12px 0 0;
  background: #fff;
  border: 2px solid #fff;
  button {
    margin-left: 10px;
    height: 34px;
    box-sizing: border-box;
    background: #FFF;
    border: none;
    color: #888E99;
    padding: 8px;
  }
  .nowrap{
    white-space: nowrap;
    letter-spacing: -0.91px;
  }
  .active {
    @extend %active;
  }
  .activeDate {
    @extend %active;
  }

}

.error{
  border: 2px solid red;
}


